.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }
  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-overflow-scrolling: touch;
  }
  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  &.owl-loaded {
    display: block;
  }
  &.owl-loading {
    opacity: 0;
    display: block;
  }
  &.owl-hidden {
    opacity: 0;
  }
  &.owl-refresh {
    .owl-item {
      visibility: hidden;
    }
  }
  &.owl-drag {
    .owl-item {
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}
.owl-carousel .owl-item,.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
}
@keyframes {
  a {
    0% {
      opacity: 1;
    }
  }
  to {
    opacity: 0;
  }
}
.owl-height {
  transition: height .5s ease-in-out;
}

