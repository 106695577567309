@charset "UTF-8";

/**
 * Основные стили
 **/

html {
  font-size: 16px;
  color: $black;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.container {
  @include min-max(320px, 100%, 0);
  background: $primary;
  display: grid;
  grid-template-areas: "header header header" //"ads1 article ads2"
  "article article article" "ads3 ads3 ads3" "footer footer footer";
  //grid-template-rows: 1fr 1fr 70px;
  grid-template-rows: 1fr 1fr 1fr 70px;
  //grid-template-columns: 20% 1fr 15%;
  grid-template-columns: 270px 1fr 160px;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  height: 100vh;
  margin: 0;
  @media (max-width: 450px) {
    display: block;
  }

}

footer, #siteAds1, #siteAds2, #siteAds3 {
  padding: 1.2em;
  background: gold;
}

header {
  grid-area: header;
}

article {
  line-height: 1.6;
  overflow-wrap: break-word;

  a {
    color: #007db5;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }

}

.article-content {
  padding: 20px 15% 30px;
}

.article-down {
  line-height: 1.2;
  font-size: 15px;
  background: white none repeat scroll 0 0;
  border: 1px dashed #f7c11f;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  margin-top: 10px;
  padding: 10px;
}

a {
  text-decoration: none;
}

p a:focus, p a:hover {
  background: -webkit-gradient(linear, left top, right top, from(#ff8a00), to(#e52e71));
  background: linear-gradient(to right, #ff8a00, #e52e71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none
}

ol, ul {
  padding-left: 15px;
  margin: 20px 0;
}

ol li, ul li {
  list-style-type: none;
  position: relative;
}

ul li::before {
  content: "\2022   ";
  color: #007db5;
  position: absolute;
  left: -15px;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin: 0 0 1.1rem;
  padding: 1.2rem 0 0;
}

.article-title {
  margin: 0 0 2.4rem;
}

article p.explanation {
  padding: 1rem 2rem;
  margin: 0 25%;
  border: 10px solid #fff;
  border-radius: 16px;
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(white), to(#ffe8cc));
  background: linear-gradient(to right, #fff, #ffe8cc);
}

article p.explanation::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid #e52e71;
  border-radius: 8px;
  pointer-events: none;
}

article p.explanation::after {
  font-family: operator mono ssm a, operator mono ssm b, operator mono, source code pro, Menlo, Consolas, Monaco, monospace;
  content: "Hey!";
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  top: -19px;
  left: 1rem;
  background: #fff;
  padding: 0 .5rem;
  font-size: .6rem;
  position: absolute;
  z-index: 1;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder
}


footer {
  grid-area: footer;
  text-align: center;
  line-height: 2;
}

main {
  grid-area: article;
  padding: 1.7% 3px;

  img {
    //width: 100%; /* need to overwrite inline dimensions */
    height: auto;
    width: 100%;
    //height: 100%;
    object-fit: cover;
    //box-shadow: 0 2px 16px rgba(0,0,0,.2);
  }
}

#siteAds1 {
  grid-area: ads1;
  display: none;
}

#siteAds2 {
  grid-area: ads2;
  display: none;
}

#siteAds3 {
  grid-area: ads3;
  padding: 1.1% 3px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


figure {
  padding: 0;
  margin: 0;
}


.gallery__main--image {
  line-height: 0;
  overflow: hidden;
}

$colors: (
        white: #ffffff,
        orange: #f5811e
);

.gallery__main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 3px;
  padding-bottom: 30px;

  &:hover {
    & ~ .gallery__main .slide {
      transform: translateY(-100%);
    }

    .slide {
      transform: translateX(100%);
    }

    & .card:hover ~ .card .slide {
      transform: translateX(-100%);
    }

    .card:hover .slide {
      transform: none;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

.article-header {
  //overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //position: absolute;
  //top: 0; right: 0; bottom: 0; left: 0;
  //background: rgba(0,0,0,.5);
  padding: 15px;
  //will-change: transform;
  //transform: translateY(100%);
  //transition: all 0.275s ease-in-out, visibility 0s 0.275s;
}

.card {
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
  box-shadow: 0 2px 16px rgba(0, 0, 0, .2);
  /*	max-width: 380px;
      height: 260px;
      margin: 5px;*/

  .slide {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    padding: 15px;
    will-change: transform;
    transform: translateY(100%);
    transition: all 0.275s ease-in-out, visibility 0s 0.275s;

    h2 {
      color: map-get($colors, white);
      font-weight: bolder;
      font-size: 28px;
      margin: 0 0 15px;
      z-index: 0;
      text-align: center;
    }

    .divider {
      margin-bottom: 15px;
      width: 30px;
      height: 5px;
      background-color: map-get($colors, orange);
    }

    p {
      color: map-get($colors, white);
      margin: 0;
    }
  }

  @media (max-width: 580px) {
    flex: none;
    width: 100%;
  }
}

.tag {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 5px 3px;
  border-radius: 2px;
  background: #d6d9e0;
  color: #606570
}

a.tag:nth-child(1) {
  background-color:#004f86;
  color:#ffffff;
}
a.tag:nth-child(2) {
  background-color:#F7DF1E;
  color:#000000
}
a.tag:nth-child(3) {
  background-color:#008335;
  color:#FFFFFF
}
a.tag:nth-child(4) {
  background-color:#CC342D;
  color:#FFFFFF
}
a.tag:nth-child(5) {
  background-color:#562765;
  color:#ffffff
}
a.tag:nth-child(6) {
  background-color:#009DF8;
  color:#FFFFFF
}
a.tag:nth-child(7) {
  background-color:#222222;
  color:#61DAF6
}
a.tag:nth-child(8) {
  background-color:#26BE00;
  color:#FFFFFF
}
a.tag:nth-child(9) {
  background-color:#212b3a;
  color:#FAAF34
}
a.tag:nth-child(10) {
  background-color:#F53900;
  color:#FFFFFF
}
a.tag:nth-child(11) {
  background-color:#000423;
  color:#71FF9E
}
a.tag:nth-child(12) {
  background-color:#d4a174;
  color:#000000
}
a.tag:nth-child(13) {
  background-color:#091b47;
  color:#b2ffe1
}

.article-header .tags .tag:hover {
  opacity: 0.9
}

.article-info {
  margin: 4px auto;
  padding: 0;
  padding: 0 3px 16px;
  font-weight: 500;
  color: #666666;
  color: #666;
  font-size: 16px;
  line-height: 1.5em;

  a {
    color: #666666;
    color: #666;
    text-decoration: none;
  }

  .profile-pic {
    width: 28px;
    height: 28px;
    border-radius: 50px;
    display: inline-block;
    vertical-align: -9px;
    margin-right: 2px;
    background: #f5f6f7;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 28px;
    }
  }

}

.article-author {
  background: linear-gradient(to right, orange, yellow, green, cyan, blue, violet);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 600;
  //font-size: 20vw;
}

.ms2Gallery > * {
  transition: .5s transform;
}


.ms2Gallery .gallery__main--image:hover {
  transform: scale(1.1);
}

.ms2Gallery .gallery__main--image img {
  filter: grayscale(100%);
  transition: .5s ease-in-out;
}
.ms2Gallery .gallery__main--image:hover img {
  filter: grayscale(0) contrast(1.11);
}

/*
.gallery__main--image img {
  background: #F1F1FA;
  width: 400px;
  height: 300px;
  display: block;
  margin: 10px auto;
  border: 0;
}*/
